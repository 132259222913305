.test-class{
  background-color: #f5deb3!important;
  justify-content: center!important;
}

.test-class2{
  justify-content: center!important;
}

.header-class{
  color: white;
  font-weight: bold;
  background-color: teal!important;
  font-size: large;
  justify-content: center!important;
}
.number-class1{
  background-color: #f5deb3;
  justify-content: center!important;
}
.number-class2{
  justify-content: center!important;
}

.row-class{
  background-color: #f5deb3;
}
.row-class3{
  background-color: #ebb248;
}

.row-class2{
  flex-direction: row;
  flex-wrap:wrap;
}


.ParentRow{
  background-color: white;
  font-size: large;
  font-weight: bold;
} 

.test-class3{
  background-color:#4fc614 ;
  font-size:large;
  justify-content: center!important;
}
.test-class4{
  background-color:#ebb248 ;
  font-size:large;
  justify-content: center!important;
}

.rg-pane.rg-pane-left {
  z-index: 1;
}

.rg-pane.rg-pane-top {
  z-index: 2 !important;
}

.rg-pane.rg-pane-right.rg-pane-top {
  z-index: 3 !important;
}

.rg-pane.rg-pane-left.rg-pane-top {
  z-index: 3 !important;
}